<template>
  <AppLayout>
    <div class="container-soft">
      <div class="soft-title"></div>
      <div class="soft-top">
        <div class="top">
          <div class="top-one">
            <p class="top-one-img"><img :src="topImg" alt="" /></p>
            <p class="top-one-title">适用于{{ topText }}的工作站配置</p>
          </div>
          <div class="top-two">
            <p class="top-two-text">联系我们，为您提供专业配置建议</p>
            <p class="top-two-btn" @click="router.push('/hard')">联系我们</p>
          </div>
        </div>
      </div>
      <div v-if="productInfoPOS == ''" style="min-height: 387px">
        <noContent style="margin-left: 500px; min-height: 387px" />
      </div>
      <div v-else>
        <div class="content-btn-1" v-if="productInfoPOS">
          <div class="left-1" style="z-index: 999;" ref="refs">
            <div
              class="btn-left"
              v-for="item in productInfoPOS"
              :key="item.id"
              :class="{ active: item.num === isActive }"
              @click.stop="handleClick(item)"
            >
              <div class="btn-left-t">
                <div class="btn-left-t-img">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <div class="btn-left-t-b">
                  <p
                    class="btn-left-t-title"
                    :class="{ active: item.num === isActive }"
                  >
                    {{ item.productName }}
                  </p>
                  <p class="btn-left-t-text">
                    {{ item.configType }}
                  </p>
                  <button
                    class="btn-btn"
                    @click.stop="routerPush(item)"
                    :class="{ active: item.num === isActive }"
                  >
                    立即购买
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-right-1" ref="refss">
            <div class="btn-one" v-if="SoftwareUnderstandingVideo || SoftwareUnderstanding">
              <!-- <p class="btn-one-text">我们对软件的理解</p> -->
              <!-- <p class="btn-one-border"></p> -->
              <div class="btn-one-iv">
                <p v-if="SoftwareUnderstandingVideo" class="btn-one-iv-video">
                  <video
                    :src="SoftwareUnderstandingVideo"
                    height="615"
                    width="1308"
                    :controls="true"
                  ></video>
                </p>
                <p v-else></p>
                <div
                  class="btn-one-img"
                  v-for="item in SoftwareUnderstanding"
                  :key="item.index"
                >
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </div>
            <div class="btn-two" v-if="PerformanceDataVideo || PerformanceData">
              <p class="btn-two-text">性能表现</p>
              <p class="btn-two-border"></p>
              <div class="btn-one-iv">
                <p v-if="PerformanceDataVideo" class="btn-one-iv-video">
                  <video
                    :src="PerformanceDataVideo"
                    height="615"
                    width="1320"
                    :controls="true"
                  ></video>
                </p>
                <p v-else></p>
                <div
                  class="btn-one-img"
                  v-for="item in PerformanceData"
                  :key="item.index"
                >
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </div>
            <div class="btn-three" v-if="AllocationAdvantage">
              <p class="btn-three-text">我们配置的优势</p>
              <p class="btn-three-border"></p>
              <div class="three-img">
                <div
                  class="btn-one-img"
                  v-for="item in AllocationAdvantage"
                  :key="item.index"
                >
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, onMounted, reactive, onUnmounted } from "vue";
import HomeBackup from "@/views/home/components/HomeBackup";
import AppLayout from "@/components/AppLayout";
import { Workstation } from "@/api/profession/index.js";
import { useRoute } from "vue-router";
import "vue3-video-play/dist/style.css";
// import useScrollTop from "@/hooks/useScrollTop";
import { useRouter } from "vue-router";
import noContent from "../../components/directives/noContent";

// const scrollTop = useScrollTop();
const isActive = ref(0);
const topImg = ref(null);
const topText = ref(null);
const route = useRoute();
const code = route.params.id;
const SoftwareUnderstanding = ref([]); // 软件的理解
const SoftwareUnderstandingVideo = ref([]); // 软件的理解视频
const PerformanceData = ref([]); // 性能表现
const PerformanceDataVideo = ref([]); // 性能表现视频
const AllocationAdvantage = ref([]); // 配置优势
const productInfoPOS = ref([]);

const getWorkstation = async (id = code) => {
  try {
    let res = await Workstation(id);
    console.log(res);
    if (res.code === 200) {
      topImg.value = res.data.softwareLogo;
      topText.value = res.data.softwareName;
	  
	  //埋点添加工作站名称
	  localStorage.setItem('topText',topText.value)
	  
	  
      productInfoPOS.value = res.data.productInfoPOS.map((item, index) => {
        return Object.assign(item, { num: index++ });
      });
      console.log(productInfoPOS.value, "111111111111");
      if (productInfoPOS.value) {
        SoftwareUnderstanding.value = productInfoPOS.value[0]?.understands;
        AllocationAdvantage.value = productInfoPOS.value[0]?.pictureAdvantages;
        PerformanceData.value = productInfoPOS.value[0]?.functionStrings;
        // console.log(productInfoPOS.value[0].understandVideos);
        if (productInfoPOS.value[0]?.understandVideos != undefined) {
          SoftwareUnderstandingVideo.value =
            productInfoPOS.value[0]?.understandVideos[0].img;
        } else {
          SoftwareUnderstandingVideo.value = "";
        }
        if (productInfoPOS.value[0]?.functionsVideos != undefined) {
          PerformanceDataVideo.value =
            productInfoPOS.value[0]?.functionsVideos[0].img;
        } else {
          PerformanceDataVideo.value = "";
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const router = useRouter();
// 页面跳转
const routerPush = (item) => {
  const params = {
    id: item.id,
    houseNo: item.configType,
  };
  if(item.machineType == 3 || item.machineType == 5 || item.machineType == 6){
    router.push({name: "detailsGoodsNew",query:params});
  }else{
    router.push({name: "detailsGoods",query:params});
  }
  // router.push({
  //   name: "detailsGoods",
  //   params,
  // });
};
const handleClick = (item) => {
  if (item) {
    isActive.value = item.num;
    SoftwareUnderstanding.value = item.understands;
    AllocationAdvantage.value = item.pictureAdvantages;
    PerformanceData.value = item.functionStrings;
    console.log(item.understandVideos);
    if (item.understandVideos != undefined) {
      SoftwareUnderstandingVideo.value = item.understandVideos[0].img;
    } else {
      SoftwareUnderstandingVideo.value = "";
    }
    if (item.functionsVideos != undefined) {
      PerformanceDataVideo.value = item.functionsVideos[0].img;
    } else {
      PerformanceDataVideo.value = "";
    }
  }
};
const data = reactive({
  oldScrollTop: 0,
});

const scrollTop = ref(0);
const refs = ref(null);
const refss = ref(null);
// const refsOne = ref(null);
const scrolling = () => {
  // 滚动条距文档顶部的距离
  scrollTop.value =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  // 滚动条滚动的距离
  let scrollStep = scrollTop.value - data.oldScrollTop;
  // console.log("header 滚动距离 ", scrollTop.value);
  // 更新——滚动前，滚动条距文档顶部的距离
  data.oldScrollTop = scrollTop.value;

  //变量windowHeight是可视区的高度
  let windowHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
  //变量scrollHeight是滚动条的总高度
  let scrollHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight;
  // if (scrollTop.value >= 0) {
  //   refs.value.style.display = "none";
  // }
  //滚动条到底部的条件
  if (scrollTop.value + windowHeight == scrollHeight) {
    //你想做的事情
    console.log("header  你已经到底部了", scrollHeight);
    // refs.value.style.display = "none";
  }
  // else {
  //   refs.value.style.display = "block";
  // }
  if (scrollStep < 0) {
    console.log("header 滚动条向上滚动了！", scrollTop.value);
    if (scrollTop.value <= 200) {
      refs.value.style.position = "absolute";
      refs.value.style.top = "0" + "px";
      refs.value.style.left = "0" + "px";
    }
  } else {
    console.log("header  滚动条向下滚动了！", scrollTop.value);
    // refs.value.style.display = "none";
    // refss.value.style.marginLeft = "529" + "px";
    if (scrollTop.value > 200) {
      refs.value.style.position = "fixed";
      refs.value.style.top = "60" + "px";
      refs.value.style.left = "0" + "px";
      refss.value.style.marginLeft = "25" + "%";
    }
  }
  // 判断是否到了最顶部
  if (scrollTop.value <= 0) {
    console.log("header 到了最顶部");
  }
};

onUnmounted(() => {
  window.removeEventListener("scroll", scrolling);
});

onMounted(() => {
  getWorkstation();
  window.addEventListener("scroll", scrolling);
});
</script>

<style lang="less" scoped>
.container-soft {
  width: 1920px;
  background-color: #fff;
  padding-bottom: 40px;
  margin: 0 auto;
  .soft-title {
    height: 70px;
    background-color: #fff;
  }
  .soft-top {
    width: 1680px;
    height: 150px;
    background: #f2f2f2;
    margin: 0 auto;
    .top {
      display: flex;
      justify-content: space-between;
      .top-one {
        display: flex;
        margin-left: 120px;
        margin-top: 24px;
        .top-one-img {
          width: 94px;
          height: 94px;
          img {
            width: 94px;
            height: 94px;
            border: 2px solid #ffffff;
            border-radius: 5px;
          }
        }
        .top-one-title {
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 34px;
          margin-left: 22px;
        }
      }
      .top-two {
        margin-right: 120px;
        margin-top: 44px;
        .top-two-text {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        .top-two-btn {
          width: 180px;
          height: 40px;
          line-height: 40px;
          background: #ce1200;
          border-radius: 20px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          margin-top: 11px;
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
  }
  .content-btn-1 {
    width: 1920px;
    display: flex;
    min-height: 450px;
    position: relative;
    .left-1 {
      // background-image: url("../../assets/images/1160.png");
      margin-top: 60px;
      width: 350px;
      height: 590px;
      margin-left: 120px;
      // padding-bottom: 30px;
      // padding-top: 36px;
      overflow-y: scroll;
      .btn-left {
        width: 303px;
        height: 180px;
        margin-bottom: 16px;
        // margin-left: 36px;
        cursor: pointer;
        background-color: #fff;
        // border-radius: 10px;
        border: 1px solid #c1c1c1;
        &:hover {
          border: 1px solid #ce1200;
        }
        &.active {
          box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
          border: 1px solid #ce1200;
          background-color: #f2f2f2;
        }

        .btn-left-t {
          display: flex;
          .btn-left-t-img {
            width: 136px;
            height: 136px;
            margin-top: 22px;
            margin-left: 17px;
            margin-right: 11px;
            img {
              width: 136px;
              height: 136px;
            }
          }
          .btn-left-t-b {
            .btn-left-t-title {
              width: 120px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-top: 20px;
              margin-bottom: 13px;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              &.active {
                color: #ce1200;
              }
            }
            .btn-left-t-text {
              width: 120px;
              height: 34px;
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-bottom: 8px;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
        .btn-btn {
          width: 120px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #c1c1c1;
          border-radius: 5px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          background-color: #fff;
          cursor: pointer;
          &.active {
            background-color: #ce1200;
            color: #fff;
            border: 1px solid #ce1200;
          }
          &:hover {
            background-color: #ce1200;
            color: #fff;
            border: 1px solid #ce1200;
          }
        }
      }
    }
    .btn-right-1 {
      margin-top: 60px;
      width: 1320px;
      // background: #f2f2f2;
      // margin-left: 540px;
      margin-left: 10px;
      .btn-one {
        .btn-one-text {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          text-align: center;
          // margin-top: 52px;
        }
        .btn-one-border {
          width: 103px;
          height: 4px;
          background: #ce1200;
          text-align: center;
          margin: 0 auto;
          margin-top: 11px;
        }
        .btn-one-iv {
          // margin-left: 11px;
          margin-top: 30px;
          .btn-one-img {
            width: 1308px;
            img {
              width: 1308px;
              /* height: 211px; */
              /* margin-bottom: 10px; */
            }
          }
          .btn-one-iv-video {
            video {
              margin-bottom: 10px;
            }
          }
        }
      }
      .btn-two {
        .btn-two-text {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 48px;
        }
        .btn-two-border {
          width: 103px;
          height: 4px;
          background: #ce1200;
          text-align: center;
          margin: 0 auto;
          margin-top: 11px;
        }
        .btn-one-iv {
          // margin-left: 11px;
          margin-top: 30px;
          .btn-one-img {
            width: 1308px;
            img {
              width: 1308px;
              /* height: 836px; */
              /* margin-bottom: 10px; */
            }
          }
          .btn-one-iv-video {
            video {
              margin-bottom: 10px;
            }
          }
        }
      }
      .btn-three {
        .btn-three-text {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 48px;
        }
        .btn-three-border {
          width: 103px;
          height: 4px;
          background: #ce1200;
          margin: 0 auto;
          margin-top: 11px;
          margin-bottom: 30px;
        }
        .three-img {
          // margin-left: 11px;
          .btn-one-img {
            width: 1308px;
            // margin-left: 60px;
            img {
              width: 1308px;
              /* height: 836px; */
              /* margin-bottom: 10px; */
            }
          }
        }
      }
    }
  }
}
.left::-webkit-scrollbar {
  width: 2px; //滚动条宽度
}
.left::-webkit-scrollbar-thumb {
  //上层
  border-radius: 1px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.left::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 0px #fff;
  border-radius: 0;
  // background: #fff;
}
.left-1::-webkit-scrollbar {
  width: 2px; //滚动条宽度
}
.left-1::-webkit-scrollbar-thumb {
  //上层
  border-radius: 1px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.left-1::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 0px #fff;
  border-radius: 0;
  // background: #fff;
}
</style>
